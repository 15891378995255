import React from "react";
import ReactDOM from "react-dom/client";

// use process.env as backup for now while on github CI
import "./config.ts";
import { App } from "./host";
import "./index.scss";
import "./styles";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// https://github.com/microsoft/TypeScript/issues/49453
declare global {
  interface Array<T> {
    findLastIndex(
      predicate: (value: T, index: number, obj: T[]) => unknown,
      thisArg?: any
    ): number;
  }
}
