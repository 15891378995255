import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import * as yup from "yup";

import { useTranslation, yupLocale } from "./shared/i18n";
import { Logo } from "./shared/icons";

yup.setLocale(yupLocale);
const NEW_URL = window.NEW_URL;

export function MigrationPage() {
  const { t } = useTranslation("MigrationPage");

  const [VPN, setVPN] = useState<boolean>();
  useEffect(() => {
    async function checkVPN() {
      try {
        const result = await fetch(NEW_URL, { mode: "no-cors" });
        setVPN(result.status !== 404);
      } catch {
        setVPN(false);
      }
    }
    checkVPN();
  }, []);

  return (
    <Container fluid className="bg-dark min-h-100">
      <Logo fill="white" />
      <Card className="my-5 mx-auto card-md">
        <Card.Body className="py-5">
          <h1 className="text-center mb-4">{t("title")}</h1>
          <h5 className="text-center mb-5">{t("url has changed")}</h5>
          <h4 className="text-center mb-5  text-decoration-underline">
            <a href={NEW_URL}>{NEW_URL}</a>
          </h4>
          <h6 className="text-center mb-4">{t("VPN required")}</h6>
          {VPN === false && (
            <h5 className="text-center mb-4 text-warning">
              {t("VPN not available")}
            </h5>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}
