import { TAppEnv } from "./config.types";

// FILE TO BE REMOVED
// use process.env variables for now if present and while on GitHub CI with a fallback on variables set on the window object

// backend API url
window.REACT_APP_API_URL =
  process.env.REACT_APP_API_URL ?? window.REACT_APP_API_URL;

// matomo user journey analytics endpoint URL
window.REACT_APP_MATOMO_URL =
  process.env.REACT_APP_MATOMO_URL ?? window.REACT_APP_MATOMO_URL;

// address search OSM resolution endpoint URL
window.REACT_APP_ADDRESS_API_URL =
  process.env.REACT_APP_ADDRESS_API_URL ?? window.REACT_APP_ADDRESS_API_URL;

// backend organization name used for rowType permissions
window.REACT_APP_ROW_TYPE_PERMISSION_ORGANIZATION =
  process.env.REACT_APP_ROW_TYPE_PERMISSION_ORGANIZATION ??
  window.REACT_APP_ROW_TYPE_PERMISSION_ORGANIZATION;

// @tanstack/react-query cacheTime and staleTime
window.REACT_APP_QUERY_CACHE_TIME_MS = Number(
  process.env.REACT_APP_QUERY_CACHE_TIME_MS ??
    window.REACT_APP_QUERY_CACHE_TIME_MS
);
window.REACT_APP_QUERY_STALE_TIME_MS = Number(
  process.env.REACT_APP_QUERY_STALE_TIME_MS ??
    window.REACT_APP_QUERY_STALE_TIME_MS
);

// support contact URL
window.REACT_APP_CONTACT_URL =
  process.env.REACT_APP_CONTACT_URL ?? window.REACT_APP_CONTACT_URL;

// app environment: development|staging|test|prod|production
window.REACT_APP_ENV = (process.env.REACT_APP_ENV ??
  process.env.NODE_ENV ??
  window.REACT_APP_ENV) as TAppEnv;

// app release version (tag)
window.REACT_APP_RELEASE =
  process.env.REACT_APP_RELEASE ?? window.REACT_APP_RELEASE;

// new URL of the app to redirect to
window.NEW_URL = process.env.NEW_URL ?? window.NEW_URL;
