import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { MigrationPage } from "../MigrationPage";

import "./i18n";

export function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MigrationPage />,
      errorElement: <MigrationPage />,
    },
  ]);

  return <RouterProvider router={router} />;
}
